/*import {gsap} from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);*/

import {DOM, globals} from './_variables';
import Parallax from 'parallax-js';


export default {

  ShowHideElements: () => {
    (function (window, $) {


      $('.ham').click(function () {
        $(this).toggleClass('open');
        $('.burger-menu').toggleClass('open');
        $('.menu-header').toggleClass('opacity-0');
        $('header').toggleClass('header-white');

      });

      $(window).scroll(function() {
        var theta = $(window).scrollTop() / 20 % Math.PI;
        $('#leftgear').css({ transform: 'rotate(' + theta + 'rad)' });
        $('#rightgear').css({ transform: 'rotate(-' + theta + 'rad)' });
      });

      // reveal items while scrolling the page

      var winWidth = $(window).width();
      var winHeight = $(window).height();
      var tollerance = winHeight / 5;

      if(winWidth < 768 ) {
        tollerance = 0;
      }

      function reveal () {

        var count = 1;

        $('.reveal.not-revealed').each(function () {
          var $this = $(this);

          if($(document).scrollTop() + winHeight - tollerance > $this.offset().top && !$this.attr('revealed')) {

            setTimeout(function () {
              $this.removeClass('not-revealed');
            }, count * 200);

            count++;
          }
        });
      }

      setTimeout(function () {
        reveal();
      }, 150);

      $(document).scroll(function () {
        reveal();
      });

      $(window).resize(function () {
        reveal();
      });

      // reveal items 2

      var winWidth2 = $(window).width();
      var winHeight2 = $(window).height();
      var tollerance2 = winHeight2 / 50;

      if(winWidth2 < 768 ) {
        tollerance2 = 0;
      }

      function reveal2 () {

        var count2 = 1;

        $('.reveal.not-revealed2').each(function () {
          var $this2 = $(this);

          if($(document).scrollTop() + winHeight2 - tollerance2 > $this2.offset().top && !$this2.attr('revealed2')) {

            setTimeout(function () {
              $this2.removeClass('not-revealed2');
            }, count2 * 100);

            count2++;
          }
        });
      }

      setTimeout(function () {
        reveal2();
      }, 150);

      $(document).scroll(function () {
        reveal2();
      });

      $(window).resize(function () {
        reveal2();
      });

      var scrollup;

      $(window).scroll(function (){

        scrollup = $(window).scrollTop();


        if (scrollup>=642){
          $('header .grid').removeClass('invert-header');
        }else {
          $('header .grid').addClass('invert-header');
        }

      });

    })(window, jQuery);
  },

  Parallax: () => {
    var scene = document.getElementById('scene');

    if(scene) {
      var parallaxInstance = new Parallax(scene);
    }

  },


  accordions: () => {
    const accordions = DOM.html.querySelectorAll('.accordion');

    if (accordions.length) {
      for (const accordion of accordions) {
        const
            title = accordion.querySelector('.accordion__title'),
            collapsible = accordion.querySelector('.accordion__collapsible')
        let isOpen = false

        title.addEventListener('click', () => {
          if (!isOpen) {
            gsap.to(collapsible, {
              onStart: () => {
                title.classList.add('accordion__title--active')
              },
              height: 'auto'
            })
          } else {
            gsap.to(collapsible, {
              onStart: () => {
                title.classList.remove('accordion__title--active')
              },
              height: 0
            })
          }

          isOpen = !isOpen
        })
      }
    }
  }
}


