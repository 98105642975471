// postcss stylesheets
import './../styles/_tailwind.pcss'
import './../styles/main.pcss'

'use strict';

import animations from './_animations'
import global from './_global';
import lazyLoad from './_lazy-load';
import slider from "./_sliders";

document.addEventListener("DOMContentLoaded", () => {
  global.pageLoadedChecker()
  global.getSreenSize()

  //animations
  animations.ShowHideElements()
  animations.Parallax()
  /*animations.accordions()*/


  // plugins
  lazyLoad.init()
  slider.galleries()
});
